.table-avatar {
  width: 40px;
  height: 40px;
}

.table--tbody-ranking > tr > td {
  vertical-align: middle;
  padding-block: 12px;
  height: 78px;
}

.table--tbody-ranking {
  tr:nth-child(1) td {
    border: none;
  }
  tr {
    td:nth-child(1) {
      width: 10%;
      text-align: center;
      font-weight: bold;
    }
    td:nth-child(2) {
      width: 10%;
    }
    td:nth-child(4) {
      width: 25%;
    }
  }
}

.slide-dashboard {
  max-height: 200px;

  img {
    object-fit: cover !important;
    height: 100% !important;
    max-height: 200px;
  }

  @media (min-width: 1200px) {
    min-height: 320px;

    img {
      min-height: 320px;
    }
  }
}

.font-bold {
  font-weight: bold;
}
